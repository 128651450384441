.timeSlot {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.timeSlotLabel {
  font-weight: bold;
  border: none;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  width: 120%;
}

.timeSlotButtons {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;

  .timeSlotButton {
    padding: 4px 100px;
    margin-bottom: 8px; /* 각 버튼 사이의 간격을 설정 */
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;
    color: black;
    &.selected {
      background-color: rgb(0, 0, 0);
      color: white;
    }
  }
}

.unselected {
  background-color: rgb(255, 255, 255); /* 연한색 배경색 */
}

.floorSlotButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  width: 120%;

  .floorSlotButton {
    padding: 4px 100px;
    margin-bottom: 8px; /* 각 버튼 사이의 간격을 설정 */
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;
    color: black;
    &.selected {
      background-color: rgb(0, 0, 0);
      color: white;
    }
  }
}
