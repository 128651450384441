.inputter__flex {
    margin: 40px;
    flex-wrap: wrap;
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .password-container {
    background-color: inherit;
    font-size: 1rem;
    text-align: center;
    color: aliceblue;
  }
  
  .num-button__flex {
    vertical-align: middle;
    width: 33%;
    height: 24%;
    line-height: 100%;
    border: 1px solid;
    overflow: hidden;
    position: relative;
    background-color: transparent;
    border-radius: 8px;
    margin-top: 15px;
  }
  
  .submit-button {
    -webkit-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
    -moz-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
    -ms-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
    -o-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
    transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
    display: block;
    margin: 20px auto;
    max-width: 180px;
    text-decoration: none;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 30px;
    color: #000000;
    font-size: 1.3rem;
    border: 1px solid grey;
  }
  
  .submit-button:hover {
    color: rgba(255, 255, 255, 0.85);
    box-shadow: rgba(141, 141, 141, 0.7) 0 0px 0px 40px inset;
  }
  
  .spread-effect {
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .spread-effect:hover {
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388;
  }
  
  .fantasy-font__2_3rem {
    font-size: 2.3rem;

    color: white;
  }