.datePickerWrapper {
  height: 400px;
  position: relative;
  touch-action: none;
}

.datePicker {
  display: flex;
  align-items: center;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  color: rgb(0, 0, 0);
  text-align: center;
  pointer-events: none;
  border-bottom: 1.5px solid black;
}
.customHeaderContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-top: 8px;
  background-color: transparent;

  .month {
    font-size: 16px;
    font-weight: 500;
    color: black;
    font-family: "Noto Sans KR", serif;
    margin-right: 50px;
    background-color: transparent;
  }

  .year {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border: none;
    font-size: 16px;
    font-weight: 500;
    padding-right: 5px;
    font-family: "Noto Sans KR", serif;
  }
}

.calenderWrapper {
  color: white;
  position: absolute;
  left: -4vw;
  // margin-left: -170px;
  @media (max-width: 768px) {
    left: -5vw;
  }
  border: none;
}

.unselectedDay {
  color: rgb(24, 18, 18);
  width: 36px;
  height: 36px;
}

.selectedDay {
  background-color: rgb(0, 0, 0);
  border-radius: 8px;
  color: white;
  &:hover {
    border-radius: 8px;
    background-color: #000000;
  }
}

.unselectedDay {
  &:hover {
    border-radius: 20px;
    background-color: rgba(rgb(40, 40, 40), 0.08);
  }
}

/* 이전 달 및 다음 달 버튼 스타일 */
.monthButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  transition: background-color 0.2s, transform 0.2s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:active {
    transform: scale(1.1);
  }
  margin-right: 10px;
}
